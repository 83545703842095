module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"redirect":false,"localeJsonSourceName":"locale","languages":["en","ar","am","sw"],"defaultLanguage":"en","ns":["messages"],"defaultNS":"messages","generateDefaultLanguagePage":true,"siteUrl":"https://barmaga.io","i18nextOptions":{"lng":"en","fallbackLng":"en"},"pages":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/images/logo_no_text.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d666e3dc8f284965e87d6c009a971efb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"barmaga.io"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
