// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-connect-github-tsx": () => import("./../../../src/pages/connect/github.tsx" /* webpackChunkName: "component---src-pages-connect-github-tsx" */),
  "component---src-pages-connect-google-tsx": () => import("./../../../src/pages/connect/google.tsx" /* webpackChunkName: "component---src-pages-connect-google-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../../../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-signin-forgot-password-tsx": () => import("./../../../src/pages/signin/forgotPassword.tsx" /* webpackChunkName: "component---src-pages-signin-forgot-password-tsx" */),
  "component---src-pages-signin-index-tsx": () => import("./../../../src/pages/signin/index.tsx" /* webpackChunkName: "component---src-pages-signin-index-tsx" */),
  "component---src-pages-signin-reset-password-tsx": () => import("./../../../src/pages/signin/resetPassword.tsx" /* webpackChunkName: "component---src-pages-signin-reset-password-tsx" */),
  "component---src-pages-signup-index-tsx": () => import("./../../../src/pages/signup/index.tsx" /* webpackChunkName: "component---src-pages-signup-index-tsx" */),
  "component---src-templates-instructor-view-tsx": () => import("./../../../src/templates/InstructorView.tsx" /* webpackChunkName: "component---src-templates-instructor-view-tsx" */),
  "component---src-templates-language-view-tsx": () => import("./../../../src/templates/LanguageView.tsx" /* webpackChunkName: "component---src-templates-language-view-tsx" */),
  "component---src-templates-lecture-view-index-tsx": () => import("./../../../src/templates/LectureView/index.tsx" /* webpackChunkName: "component---src-templates-lecture-view-index-tsx" */),
  "component---src-templates-level-view-tsx": () => import("./../../../src/templates/LevelView.tsx" /* webpackChunkName: "component---src-templates-level-view-tsx" */),
  "component---src-templates-tag-view-tsx": () => import("./../../../src/templates/TagView.tsx" /* webpackChunkName: "component---src-templates-tag-view-tsx" */)
}

